#CookieMessage {
    background: #eee;
    display: block;
    padding: 30px 30px 25px;
    position: relative;
    z-index: 10000;

    h2,
    p {
        margin-top: 0;
    }

    &.Bottom {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
    }
}

.CookieColContainer {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.CookieCol1 {
    flex: 1 1 auto;
}

.CookieCol2 {
    flex: 1 0 auto;
    text-align: right;
    width: 25%;
}

.CookieCol + .CookieCol {
    margin-left: 2%;
}

#CookieMessage .CookieButton {
    background: white;
    border: 2px solid black;
    border-radius: 0;
    color: black;
    cursor: pointer;
    font-size: inherit;
    margin-bottom: 1em;
    padding: 1em 1.5em;
}

#CookieMessage.Mobile .CookieColContainer {
    flex-wrap: wrap;
}
#CookieMessage.Mobile .CookieCol {
    margin: 0;
    text-align: left;
    width: 100%;
}

#CookieMessage .CloseDefault {
    border-radius: 50%;
    height: 40px;
    padding: 20px;
    position: relative;
    width: 40px;
}

#CookieMessage .CloseDefault::before, #CookieMessage .CloseDefault::after {
    background: black;
    content: '';
    display: block;
    height: 5px;
    left: 10px;
    position: absolute;
    top: 18px;
    width: 20px;
}

#CookieMessage .CloseDefault::before {
    transform: rotate(45deg);
}

#CookieMessage .CloseDefault::after {
    transform: rotate(-45deg);
}

#CookieMessage.Bottom .CloseDefault {
    position: absolute;
    right: 5%;
    top: -18px;
}

/* Hover, active state */
#CookieMessage .CookieButton:hover, #CookieMessage .CookieButton:focus {
    background: black;
    color: white;
}

#CookieMessage .CloseDefault:hover::before, #CookieMessage .CloseDefault:hover::after,
#CookieMessage .CloseDefault:focus::before, #CookieMessage .CloseDefault:focus::after {
    background: white;
}
